<!-- Localized -->
<template>
    <modal-content 
      ref="reschedule-sequence"
      name="reschedule-sequence" 
      :title="$t('components.dashboard.email.select_date')" 
      :maxWidth="400"
      class="reschedule-sequence__modal"
      :show-footer="false"
      @before-close="$emit('close')"
      @close="hideModal"
    >
      <template #header>
        <div class="py-2 px-4 flex items-center justify-between bg-primary rounded-t-2xl">
              <h3 class="text-white-text font-semibold text-lg flex items-center gap-2">
                {{ $t('components.dashboard.email.select_date') }}
              </h3>
              <span class="cursor-pointer text-white-text cross" @click.stop="hideModal">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512" fill="white" class="h-7 w-7">
                      <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"/>
                  </svg>
              </span>
        </div>
      </template>
      <template #content>
          <div class="p-6 flex justify-between">
              <Field class="w-full" :title="$t('general.date')" type="date" v-model="reschedule_date" :disabled-date="disabledRange">
                <template #icon>
                  <DateIcon />
                </template>
              </Field>
          </div>
      </template>
    </modal-content>
</template>

<script>
import modalContent from "@shared/modal-content";
import { FormatDate } from "@/plugins/functions";
import Field from "@/components/sc-journey/field-modal.vue";
import DateIcon from '@/assets/icons/dashboard/date.svg';
import { DateTime } from 'luxon';

export default {
  name: "reschedule-sequence",
  components: {
      Field,
      DateIcon,
      modalContent,
  },
  props: {
    isRescheduleSequenceOpen: {
        type: Boolean,
        default: false,
    },
    loading: {
        type: Boolean,
        default: () => false,
    },
    date: {
      type: String,
      default: () => '',
    },
  },
  mounted() {},
  computed: {
    reschedule_date: {
      get() {
        return this.date;
      },
      set(val) {
        const date = this.formatDate(val);
        this.$emit('submit', date);
        this.hideModal();
      }
    }
  },
  methods: {
    FormatDate,
    formatDate(date) {
      return DateTime.fromJSDate(new Date(date)).toFormat("yyyy-MM-dd");
    },
    disabledRange(date) {
      return date < new Date();
    },
    showModal() {
      this.$refs["reschedule-sequence"].showModal();
    },
    hideModal() {
      this.$refs["reschedule-sequence"].hideModal();
    }
  },
};
</script>
<style lang="scss">
.reschedule-sequence__modal .modalContent__content {
  padding: 0;
}
</style>
